import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import CustomerServiceBooking from "./../CustomerServiceBookingPage/CustomerServiceBooking";
import OrderTimeline from "./OrderTimeline";
import OrderOverbooking from "./OrderOverbooking";
import { store } from "../../../reducers/Persist";
import { Button } from "react-bootstrap";
import { Refresh, Logout } from "../AdminPage/AdminNav";
import RescheduleAppointment from "../RescheduleAppointment/RescheduleAppointment";
import CheckPrescription from "./CheckPrescription";

export default function CustomerServiceDashboard(props) {
  const [page, setPage] = useState("csbooking");
  const location = useLocation();
  const allTabs = [
    {
      title: "CS Booking",
      value: "csbooking",
      roleAllowed: new Set(["admin", "assistant", "external_reschedule"])
    },
    {
      title: " Order Timeline",
      value: "orderTimeline",
      roleAllowed: new Set(["admin", "assistant", "external_view_only"])
    },
    {
      title: "Reschedule Appointment",
      value: "rescheduleAppointment",
      roleAllowed: new Set(["admin", "assistant", "external_reschedule"])
    },
    {
      title: "Check Prescription",
      value: "checkPrescription",
      roleAllowed: new Set(["admin", "assistant", "external_view_only"])
    }
  ];
  const roleAccordingTabs = allTabs.filter(t =>
    t.roleAllowed.has(store.getState().userReducer.role_name)
  );
  const renderSwitch = () => {
    switch (page) {
      case "csbooking":
        return <CustomerServiceBooking />;
      case "orderTimeline":
        return <OrderTimeline />;
      case "rescheduleAppointment":
        return <RescheduleAppointment />;
      case "orderOverbooking":
        return <OrderOverbooking />;
      case "checkPrescription":
        return <CheckPrescription />;
      default: {
        return <CustomerServiceBooking />;
      }
    }
  };
  useEffect(() => {
    setPage(location.pathname.split("/cs/")[1]);
  }, [location]);
  return (
    <div>
      <>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick
          pauseOnHover
        />
        <div className="dashboard-page-container">
          <div>
            <ProSidebar>
              <h6
                style={{
                  padding: "10px",
                  backgroundColor: "rgb(20,20,20)",
                  color: "white"
                }}
              >
                Customer Service Dashboard
              </h6>
              <Menu iconShape="square">
                {roleAccordingTabs.map(t => {
                  return (
                    <MenuItem
                      className={page === `${t.value}` ? "cs-active" : ""}
                    >
                      <Link
                        className={page === `${t.value}` ? "active" : ""}
                        to={`/cs/${t.value}`}
                      >
                        {t.title}
                      </Link>
                    </MenuItem>
                  );
                })}
                <hr />
                <MenuItem>
                  <Refresh />
                </MenuItem>
                <MenuItem>
                  <Logout />
                </MenuItem>
              </Menu>
            </ProSidebar>
          </div>
          <div>{renderSwitch()}</div>
        </div>
      </>
    </div>
  );
}
