import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../constants/apiList.js";
import Table2 from "../../common/Tables/Table2";
import Loader from "react-loader-spinner";
import CONFIG_CONSTANTS from "../../../constants/apiList";

export default function OrderOverbooking() {
  const [rows, setRows] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    axios
      .post(config.url.ORDER_OVERBOOKINGS, {})
      .then(res => {
        generateRows(res.data.body.data);
        setShowLoader(false);
      })
      .catch(err => {
        console.log(err);
        setRows([]);
        setShowLoader(false);
      });
  }, []);

  const cols = [
    {
      title: "Order ID",
      field: "orderId"
    },
    {
      title: "Name",
      field: "name"
    },
    {
      title: "Phone",
      field: "phone"
    },
    {
      title: "Email",
      field: "email"
    },
    {
      title: "Brand",
      field: "brand"
    },
    {
      title: "Selected Date",
      field: "selectedDate"
    },
    {
      title: "Selected Time",
      field: "selectedTime"
    }
  ];
  const generateRows = data => {
    data = data || [];
    const rows = [];
    console.log("---data----", data);
    for (let i = 0; i < data.length; i++) {
      const order = data[i];
      order.req_data = order.req_data || {};
      const row = {
        orderId: order.req_data.source_id,
        name: order.req_data.patient_name,
        phone: order.req_data.phone,
        email: order.req_data.email,
        brand: order.req_data.brand,
        selectedDate: order.req_data.date,
        selectedTime: order.req_data.slot,
        bookingToken: order.booking_token
      };
      rows.push(row);
    }
    setRows(rows);
  };
  const appointmentAction = [
    {
      icon: "launch",
      tooltip: "Order Appointment",
      onClick: (event, rowData) => {
        const domain =
          rowData.brand == "MM"
            ? CONFIG_CONSTANTS.url.MAN_MATTERS_DOMAIN
            : CONFIG_CONSTANTS.url.BODYWISE_DOMAIN;
        window.open(
          `${domain}order-appointment?booking_token=${rowData.bookingToken}`,
          "_blank"
        );
      }
    }
  ];
  return (
    <>
      {/* {showLoader ? (
        <div className="loader-cm" style={{ marginTop: "20px" }}>
          Loading... Please Wait
          <div className="Loader-box">
            <Loader type="TailSpin" color="#00BFFF" height={30} width={30} />
          </div>
        </div>
      ) : (
        <Table2
          cols={cols}
          rows={rows}
          title="Order Overbooking"
          actions={appointmentAction}
          pageSize={20}
        />
      )} */}
      <h1> This page has been deprecated </h1>
    </>
  );
}
