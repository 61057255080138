import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Tab, { tabs } from "./tabs";
import { useLocation } from "react-router-dom";
import { Logout, Refresh } from "../AdminPage/AdminNav";
import { store } from "../../../reducers/Persist";

export default function CustomDashboard() {
  const [page, setPage] = useState(Object.keys(tabs)?.[0] || "");
  const location = useLocation();
  useEffect(() => {
    const tabKey = location?.pathname?.split?.("/custom/dashboard/")?.[1];
    if (!tabKey) return;
    setPage(tabKey);
  }, []);
  const showTab = tab => {
    return tab?.allowedRoles?.includes(
      store?.getState()?.userReducer?.role_name
    );
  };
  return (
    <div className={styles["custom-dashboard"]}>
      <div className={styles["tabs"]}>
        <div className={styles["logo"]}>
          <img src="https://ik.ourlittlejoys.com/mw-img/Consult_-_Dochub/dochub_eCPDFST_3j.png?updatedAt=1678881885291" />
          <div className={styles["button-container"]}>
            <Logout />
            <Refresh />
          </div>
        </div>
        <div className={styles["tab-inner-wrapper"]}>
          {Object.keys(tabs).map(tabKey => {
            if (!showTab(tabs[tabKey])) return null;
            return (
              <Tab
                isActive={page === tabKey}
                tab={tabs[tabKey]}
                tabKey={tabKey}
                setPage={setPage}
              />
            );
          })}
        </div>
      </div>
      <div className={styles["content"]}>{tabs?.[page]?.component}</div>
    </div>
  );
}
