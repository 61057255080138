import React from "react";
import { Link } from "react-router-dom";
import DoctorAi from "../DoctorAi/DoctorAi";
import styles from "./styles.module.scss";
import { roles } from "../../../constants/roles.constants";
const { ADMIN, COACH } = roles;

export const tabs = {
  "doctor-AI": {
    title: "Doctor AI",
    component: <DoctorAi />,
    allowedRoles: [ADMIN, COACH]
  }
};
const prefix = "/custom/dashboard";

export default function Tab({
  isActive = false,
  tab = {},
  tabKey = "",
  setPage = () => {}
}) {
  return (
    <Link
      className={isActive ? styles["tab-active"] : styles["tab"]}
      to={`${prefix}/${tabKey}`}
    >
      <div className={styles["tab-wrapper"]} onClick={() => setPage(tabKey)}>
        {tab.title}
      </div>
    </Link>
  );
}
