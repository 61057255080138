import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./root.css";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import NewLoginPage, {
  NewOTPLoginPage
} from "./components/core/LoginPage/NewLoginPage";
import LoginCallback from "./components/core/LoginPage/LoginCallback";
import CustomerServiceBookingPage from "./components/core/CustomerServiceBookingPage/CustomerServiceBooking";
import PrescriptionPage from "./components/core/PrescriptionPage/PrescriptionPage";
// import AdminPage from "./components/core/AdminPage/AdminPage";
import DashboardPage2 from "./components/core/NewDoctorDashboard/DashboardPage2";
import DashboardPage2V2 from "./components/core/NewDoctorDashboard/V2/DashboardPage2";
import CustomerServiceDashboard from "./components/core/CustomerServicePage/CustomerServiceDashboard";
import OrderTimeline from "./components/core/CustomerServicePage/OrderTimeline";
import RxWriterPage from "./components/rxwriter";
import withSocketHOC from "./components/core/NewDoctorDashboard/CurrentAppointment/withSocketHOC";
import CheckoutModal from "./components/core/CheckInOutModal/check.out.modal";
import { toast } from "react-toastify";
import axios from "axios";
// import MealDetails from "./components/core/MealDetails/MealDetails";
// // import MealDetails2 from "./components/core/MealDetails2/MealDetails2";
// import MealDetails2 from "./components/core/MealDetails2/MealDetails2";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-widgets/dist/css/react-widgets.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-datepicker/dist/react-datepicker.css";
import PopupBox from "./components/common/PopupBox/PopupBox";
import AdminPage2 from "./components/core/AdminPage/AdminPage2";
import Axios from "axios";
import config from "./constants/apiList";
import { PersistGate } from "redux-persist/integration/react";
// export const store = createStore(rootReducer);
import { store, persistor } from "./reducers/Persist";
import clevertap from "clevertap-web-sdk";
import { playNotification } from "./helpers/index";
import { iaEnabledForDoctor } from "./helpers/features.helper";
import TagManager from "react-gtm-module";
import mixpanel from "mixpanel-browser";
import { addToGTM } from "./helpers/tagManager.helper";
import { GTM_EVENTS } from "./constants/tagManager.constants";
import {
  CustomRolePrivateRoute,
  AdminPrivateRoute,
  CSPrivateRoute,
  LoginNormalRoute,
  DoctorPrivateRoute
} from "./RouteInit";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole } from "@sentry/integrations";
import CustomDashboard from "./components/core/CustomDashboard";

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://a6f5b4a2c91d41948c196028b0305725@o4504881246765056.ingest.sentry.io/4504881251811328",
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllInputs: false,
        maskAllText: false
      }),
      new CaptureConsole({
        levels: ["error"]
      })
    ],
    tracesSampleRate: 0.1,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.05
  });
}
// CleverTap Init Operations
let cleverTapKey =
  process.env.REACT_APP_ENV === "production"
    ? "886-499-446Z"
    : "TEST-986-499-446Z";

clevertap.init(cleverTapKey);

// GTM Init Operations
const gtmId = config?.properties?.GTM_ID;
TagManager.initialize({ gtmId });

// Mixpanel Init Operations
const mixpanelToken = config?.properties?.MIXPANEL_TOKEN;
mixpanel.init(mixpanelToken, {
  debug: process.env.REACT_APP_ENV !== "production"
});
window.mixpanel = mixpanel;

const CheckInWrapper = withSocketHOC(React.memo(CheckIn));

function CheckIn(props) {
  const [isCheckedIn, setIsCheckedIn] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckedOut, setIsCheckedOut] = useState(false);
  const [nutritionistList, setNutritionistList] = useState([]);
  const [loggedInDr, setLoggedInDr] = useState(null);
  const [nutritionListLoading, setNutritionListLoading] = useState(true);
  const [doctorInfoLoading, setDoctorInfoLoading] = useState(false);
  const [curBrand, setCurBrand] = useState("");
  const doctorId = useSelector(state => state.userReducer?.hash_id);
  useEffect(() => {
    Axios.post(config.url.IS_CHECKEDIN, {}).then(res => {
      setIsCheckedIn(res.data.flag);
    });
  }, [isCheckedIn]);

  useEffect(() => {
    const id = props.match?.params?.id;
    if (!(id && id.length)) return setNutritionListLoading(false);
    const idParameters = id.split("-");
    const brand = idParameters[0];
    setCurBrand(idParameters[0]);
    store.dispatch({
      type: "SET_CURRENT_APPOINTMENT_ID",
      payload: idParameters[2]
    });
    if (store.getState().observationReducer[idParameters[2]] === undefined) {
      store.dispatch({ type: "ADD_APPOINTMENT_ID", payload: idParameters[2] });
    }
    store.dispatch({ type: "CHANGE_ID", data: idParameters[2] });
    const requestData = {
      categories: ["weight"],
      brand
    };
    // setNutritionListLoading(true);
    Axios.post(config.url.GET_DOCTORS_BY_CATEGORY_ES, requestData)
      .then(res => {
        if (res?.data?.status) {
          setNutritionistList(
            res?.data?.data?.doctorsByCategory?.map(doctor => doctor.doctorId)
          );
          setNutritionListLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setNutritionListLoading(false);
      });
    const payload = {
      doctor_id: doctorId
    };
    Axios.post(config.url.GET_DOCTOR_INFO_URL, payload)
      .then(res => {
        if (res.status === 200) {
          setLoggedInDr(res.data.body.data);
          store.dispatch(
            { type: "CHANGE_LOGGEDIN_DR", data: res.data.body.data },
            () => {
              setDoctorInfoLoading(false);
            }
          );
        }
      })
      .catch(err => {
        setDoctorInfoLoading(false);
        console.log(err);
      });
  }, [props.match.params.id, doctorId]);
  useEffect(() => {
    if (isCheckedOut) {
      playNotification();
    }
  }, [isCheckedOut]);

  const handleCheckInRequest = () => {
    setIsLoading(true);
    Axios.post(config.url.SAVE_CHECKIN_CHECKOUT, {
      record_type: "ci"
    })
      .then(res => {
        addToGTM({
          event: GTM_EVENTS?.CHECK_IN,
          checkinData: {
            checkedInBy: "doctor"
          }
        });
        setIsCheckedIn(true);
        setIsCheckedOut(false);
      })
      .catch(err => {
        toast.error("Check in failed");
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const userData = store?.getState()?.userReducer;
  return isCheckedOut ? (
    <CheckoutModal
      titleText="You have been"
      onCheckInClick={handleCheckInRequest}
      isLoading={isLoading}
    />
  ) : !isCheckedIn ? (
    <CheckoutModal
      titleText="You are currently"
      onCheckInClick={handleCheckInRequest}
      isLoading={isLoading}
    />
  ) : (
    <Switch>
      {doctorInfoLoading || nutritionListLoading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader type="TailSpin" />
        </div>
      ) : nutritionistList.includes(loggedInDr?.doctor_id) ? (
        <DoctorPrivateRoute
          path="/prescription/:id?"
          component={PrescriptionPage}
        />
      ) : (
        <DoctorPrivateRoute
          path="/prescription/:id?"
          component={RxWriterPage}
        />
      )}
      <DoctorPrivateRoute path="/history" component={PrescriptionPage} />
      {!iaEnabledForDoctor(userData?.brands) ? (
        <DoctorPrivateRoute
          path="/dashboard"
          component={() => <DashboardPage2 setIsCheckedOut={setIsCheckedOut} />}
        />
      ) : (
        <DoctorPrivateRoute
          path="/dashboard"
          component={() => (
            <DashboardPage2V2 setIsCheckedOut={setIsCheckedOut} />
          )}
        />
      )}
    </Switch>
  );
}
const initialize = (errorCb, initCb) => {
  // Axios Init Operations
  Axios.interceptors.request.use(
    req => {
      if (req.url.indexOf("api.mosaicwellness.in/prescription-generator") > 0) {
        req.withCredentials = true;
      }

      if (!!req.url) {
        let url = req.url.split("/");
        if (url[url.length - 1] !== "hitEveryMin") {
          localStorage.setItem("lastRequestTime", Date.now());
        }
      }
      return req;
    },
    error => Promise.reject(error)
  );

  if (localStorage.getItem("jwt")) {
    Axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("jwt");
  }
  Axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (!!error && error.response.status === 401) {
        const url = new URL(window.location.href);
        const onLoginPage = url?.pathname === "/login";

        if (!onLoginPage) {
          toast.error(
            "Invalid Session or Session Expired | Redirecting to Login Page",
            {
              position: "top-right",
              toastId: "401-error"
            }
          );
        }
        const DELAY = onLoginPage ? 0 : 2000;
        setTimeout(async () => {
          store.dispatch({ type: "CLEAR" });
          localStorage.removeItem("jwt");
          await Axios.get(config.AUTH.LOGOUT, {
            withCredentials: true
          });
          if (!onLoginPage) {
            window.location.href = "/login";
          }
        }, DELAY);
      }
      return Promise.reject(error);
    }
  );

  initCb();
};

function Root() {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isAuthLoading, setIsAuthLoading] = React.useState(true);

  React.useEffect(() => {
    initialize(
      err => toast.error(err),
      () => setIsInitialized(true)
    );
  }, []);

  async function routeUser() {
    try {
      if (!localStorage.getItem("jwt")) {
        store.dispatch({ type: "CLEAR" });
        await axios.get(config.AUTH.LOGOUT, { withCredentials: true });
        setIsAuthLoading(false);
        return;
      }

      const response = await axios.post(config.url.VERIFY_TOKEN, {});

      if (response.data?.statusCode !== 200) {
        store.dispatch({ type: "CLEAR" });
        await axios.get(config.AUTH.LOGOUT, { withCredentials: true });
        localStorage.removeItem("jwt");
        axios.defaults.headers.common["Authorization"] = "";
        setIsAuthLoading(false);
        return;
      }

      const authVerifyResponse = await axios.get(config.url.DOC_VERIFY_TOKEN, {
        withCredentials: true
      });

      if (authVerifyResponse.status !== 200) {
        throw new Error("Authentication Failed");
      }

      localStorage.setItem("jwt", response.data.body.jwt);
      axios.defaults.headers.common["Authorization"] =
        localStorage.getItem("jwt");
      const payload = response.data?.body?.user;
      const payloadRoleName = payload?.role_name;
      if (!store.getState()?.userReducer?.role_name) {
        if (!payloadRoleName) {
          throw new Error("Invalid Role Name Found");
        }
        store.dispatch({ type: "USER", payload: payload });
      } else if (store.getState()?.userReducer?.role_name !== payloadRoleName) {
        store.dispatch({ type: "USER", payload: payload });
      }

      if (process.env.REACT_APP_ENV === "production") {
        Sentry.setUser({
          email: payload?.doctor_email,
          id: payload?.doctor_id
        });
      }

      setIsAuthLoading(false);
    } catch (err) {
      console.log(err);
      setIsAuthLoading(false);
      localStorage.removeItem("jwt");
      axios.defaults.headers.common["Authorization"] = "";
      store.dispatch({ type: "CLEAR" });
      axios.get(config.AUTH.LOGOUT, { withCredentials: true });
      return;
    }
  }
  React.useEffect(() => {
    if (isInitialized) {
      routeUser();
    }
  }, [isInitialized]);

  if (!isInitialized || isAuthLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader type="TailSpin" />
      </div>
    );
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <Switch>
            <Route exact path="/login-callback" component={LoginCallback} />
            <LoginNormalRoute exact path="/" component={NewOTPLoginPage} />
            <LoginNormalRoute exact path="/login" component={NewOTPLoginPage} />
            <LoginNormalRoute
              exact
              path="/otp-login"
              component={NewOTPLoginPage}
            />
            <LoginNormalRoute path="/cslogin" component={NewOTPLoginPage} />
            <DoctorPrivateRoute
              path={["/prescription/:id?", "/history"]}
              component={CheckIn}
            />
            <DoctorPrivateRoute path="/dashboard" component={CheckInWrapper} />
            <CSPrivateRoute
              path="/csbooking"
              component={CustomerServiceBookingPage}
            />
            <CSPrivateRoute
              path="/csorder-timeline"
              component={OrderTimeline}
            />
            <CSPrivateRoute path="/cs" component={CustomerServiceDashboard} />
            <AdminPrivateRoute path="/admin" component={AdminPage2} />
            <CustomRolePrivateRoute
              path="/custom/dashboard"
              component={CustomDashboard}
            />
            <Redirect to="/login" />
          </Switch>
          <PopupBox />
        </Router>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
