import React, { useState } from "react";
import { InputAdornment, IconButton, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "react-bootstrap";
import moment from "moment";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import axios from "axios";
import Loader from "react-loader-spinner";

import config from "../../../constants/apiList";

export default function OrderTimeline() {
  const [orderId, setOrderId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [brand, setBrand] = useState("MM");
  const [customerData, setCustomerData] = useState({});
  const [orderData, setOrderData] = useState({});
  const [message, setMessage] = useState("");
  const [lastOrders, setLastOrders] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const idChangeHandler = evt => {
    const name = evt.target.name;
    if (name === "orderId") {
      setOrderId(evt.target.value);
      setPatientId("");
    } else if (name === "patientId") {
      setPatientId(evt.target.value);
      setOrderId("");
    }
  };
  const searchButtonHandler = evt => {
    setCustomerData({});
    setOrderData({});
    setLastOrders([]);
    setMessage("");
    if (!orderId && !patientId) return;
    setShowLoader(true);
    const searchBy = orderId ? "orderId" : "patientId";
    if (searchBy === "orderId") {
      const requestData = {
        brand,
        order_id: orderId
      };
      axios
        .post(config.url.ORDER_DETAILS_URL, requestData)
        .then(res => {
          console.log(res);
          setCustomerData(res.data.body.data);
          const orderDataRequestData = {
            contact_no: res.data.body.data.phone,
            brand
          };
          axios
            .post(config.url.GET_ORDER_DATA, orderDataRequestData)
            .then(res2 => {
              if (res2.data.status === "Failed") {
                setMessage("Unable to get the data!");
                setShowLoader(false);
              }
              console.log(res2.data.data);
              setOrderData(res2.data.data ? res2.data.data : {});
              setLastOrders(res2.data.eventData ? res2.data.eventData : []);
              setMessage("No record found!");
              setShowLoader(false);
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
    } else {
      const requestData = {
        brand,
        patientId
      };
      axios
        .post(config.url.GET_CUSTOMER_DATA, requestData)
        .then(customerDataResponse => {
          if (!customerDataResponse.data.success) {
            setMessage("No record found!");
            setShowLoader(false);
            return;
          }
          setCustomerData(customerDataResponse.data.data);
          console.log("Customer data", customerDataResponse.data.data);
          const orderDataRequestData = {
            contact_no: customerDataResponse.data.data.phone,
            brand
          };
          axios
            .post(config.url.GET_ORDER_DATA, orderDataRequestData)
            .then(res2 => {
              if (res2.data.status === "Failed") {
                setMessage("Unable to get the data!");
                setShowLoader(false);
              }
              if (res2.data.data.fname) {
                setCustomerData({
                  ...customerDataResponse.data.data,
                  name: `${res2.data.data.fname} ${res2.data.data.lname}`
                });
              }
              setOrderData(res2.data.data ? res2.data.data : {});
              setLastOrders(res2.data.eventData ? res2.data.eventData : []);
              setMessage("No record found!");
              setShowLoader(false);
            })
            .catch(err => console.log(err));
        });
    }
  };
  return (
    <div style={{ padding: "10px" }}>
      <h4
        className="sectionHeading"
        style={{ color: "#3f51b5", marginBottom: "5px" }}
      >
        Order Timeline
      </h4>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>
      <div>
        <b>Search By</b>
      </div>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <div style={{ margin: "5px" }}>
          <span
            style={
              orderId
                ? { color: "#3f51b5", fontWeight: "bold" }
                : { color: "rgb(120,120,120)" }
            }
          >
            Order ID
          </span>
          <br />
          <input
            type="text"
            className="input"
            value={orderId}
            name="orderId"
            onChange={idChangeHandler}
            onKeyDown={evt => {
              if (evt.keyCode === 13) searchButtonHandler(evt);
            }}
          />
        </div>
        <div style={{ margin: "5px" }}>
          <span
            style={
              patientId
                ? { color: "#3f51b5", fontWeight: "bold" }
                : { color: "rgb(120,120,120)" }
            }
          >
            Patient ID
          </span>
          <br />
          <input
            type="number"
            className="input"
            value={patientId}
            name="patientId"
            onChange={idChangeHandler}
            onKeyDown={evt => {
              if (evt.keyCode === 13) searchButtonHandler(evt);
            }}
          />
        </div>
        <IconButton
          color="primary"
          aria-label="Go"
          onClick={searchButtonHandler}
          style={{ width: "60px", height: "60px" }}
        >
          <SearchIcon />
        </IconButton>
      </div>
      {orderData.user_signed_up ? (
        <>
          <div
            style={{
              margin: "5px",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid rgb(240,240,240)"
            }}
          >
            <h6 className="sectionHeading" style={{ color: "#3f51b5" }}>
              Customer Details
            </h6>
            <div>
              <span>Name: </span>
              <span>
                <b>{customerData.name}</b>
              </span>
            </div>
            <div>
              <span>Email: </span>
              <span>
                <b>{customerData.email}</b>
              </span>
            </div>
            <div>
              <span>Phone Number: </span>
              <span>
                <b>{customerData.phone}</b>
              </span>
            </div>
          </div>

          <div
            style={{
              margin: "5px",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid rgb(240,240,240)"
            }}
          >
            <div>
              <span>Last Order placed on: </span>
              <span>
                <b>
                  {orderData.last_order_date
                    ? moment(orderData.last_order_date).format("DD MMM YYYY")
                    : "N/A"}
                </b>
              </span>
            </div>
            <div>
              <span>Total Orders: </span>
              <span>
                <b>{orderData.no_of_order_placed}</b>
              </span>
            </div>
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContentk: "space-between"
              }}
            >
              <div>
                <div>
                  <span>Last Appointment: </span>
                  <span>
                    <b>
                      {orderData.appointment_date
                        ? moment(orderData.appointment_date).format(
                            "DD MMM YYYY"
                          )
                        : "N/A"}
                    </b>
                  </span>
                </div>
                <div>
                  <span>Last Doctor Consulted: </span>
                  <span>
                    <b>
                      {orderData.last_doctor_consulted
                        ? orderData.last_doctor_consulted
                        : "N/A"}
                    </b>
                  </span>
                </div>
              </div>
              <div style={{ margin: "auto" }}>
                <div>
                  <span>Next Appointment: </span>
                  <span>
                    <b>
                      {orderData.future_appointment_date
                        ? moment(orderData.future_appointment_date).format(
                            "DD MMM YYYY"
                          )
                        : "N/A"}
                    </b>
                  </span>
                </div>
                <div>
                  <span>Doctor Scheduled: </span>
                  <span>
                    <b>
                      {orderData.future_appointment_doctor
                        ? orderData.future_appointment_doctor
                        : "N/A"}
                    </b>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              margin: "5px",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid rgb(240,240,240)"
            }}
          >
            <h6 className="sectionHeading" style={{ color: "#3f51b5" }}>
              Last Order Status
            </h6>
            {orderData.clickpost_status &&
            orderData.clickpost_status.length > 0 ? (
              <>
                <div style={{ margin: "2px" }}>
                  <span>AWB: </span>
                  <span>
                    <b>{orderData.clickpost_status[0].AWB}</b>
                  </span>
                </div>
                <div style={{ margin: "2px" }}>
                  <span>Partner: </span>
                  <span>
                    <b>
                      {brand === "MM"
                        ? orderData.clickpost_status[0].delivery_partner
                        : orderData.clickpost_status[0].deliveryPartner}
                    </b>
                  </span>
                </div>
                <div style={{ margin: "2px" }}>
                  <span>Current Clickpost Status: </span>
                  <span>
                    <b>{orderData.clickpost_status[0].status}</b>
                  </span>
                </div>
                <div style={{ margin: "2px" }}>
                  <span>
                    <a href={orderData.tracking_details} target="_blank">
                      <Button
                        variant="link"
                        style={{ border: "1px solid rgb(230,230,230)" }}
                      >
                        View Tracking Details
                      </Button>
                    </a>
                  </span>
                </div>
              </>
            ) : null}
          </div>
          <Button
            variant="warning"
            size="lg"
            style={{ width: "100%", margin: "10px 0px" }}
          >
            CREATE TICKET
          </Button>
        </>
      ) : (
        <>
          {showLoader ? (
            <div className="loader-cm" style={{ marginTop: "20px" }}>
              Loading... Please Wait
              <div className="Loader-box">
                <Loader
                  type="TailSpin"
                  color="#00BFFF"
                  height={30}
                  width={30}
                />
              </div>
            </div>
          ) : null}
          <div style={{ textAlign: "center", padding: "20px" }}>{message}</div>
        </>
      )}
      {lastOrders.length > 0 ? (
        <>
          <div
            style={{
              margin: "5px",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid rgb(240,240,240)"
            }}
          >
            <h6 className="sectionHeading" style={{ color: "#3f51b5" }}>
              Last Orders
            </h6>
            {lastOrders.map(order => {
              if (order.order_id == orderData.last_order_id) return null;
              return (
                <div
                  style={{
                    backgroundColor: "rgb(240,240,255)",
                    padding: "10px",
                    margin: "5px 0px"
                  }}
                >
                  <div style={{ margin: "5px" }}>
                    <span>Order ID: </span>
                    <span>
                      <b>{order.order_id} </b>
                    </span>
                    <span style={{ marginLeft: "20px" }}>Date: </span>
                    <span>
                      <b>
                        {order.created_dttm
                          ? moment(order.created_dttm).format("DD MMM YYYY")
                          : "N/A"}{" "}
                      </b>
                    </span>
                  </div>
                  {/* <div style={{ margin: "5px" }}>
                    <span>Status: </span>
                    <span>
                      <b>{order.order_status} </b>
                    </span>
                  </div> */}
                  <div style={{ margin: "5px" }}>
                    <span>
                      <a
                        href={
                          brand === "MM"
                            ? `https://www.manmatters.com/track-order/?order-id=${order.order_id}&email=${order.email}`
                            : `https://bebodywise.com/orders/track?orderNo=${order.order_id}&email=${order.email}`
                        }
                        target="_blank"
                      >
                        <Button
                          variant="info"
                          style={{ border: "1px solid rgb(230,230,230)" }}
                        >
                          View Tracking Details
                        </Button>
                      </a>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
}
