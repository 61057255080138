import "./DoctorAi.scss";
import Axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  defaultShapeStyle,
  ReactPictureAnnotation
} from "react-picture-annotation";
import { Deletebutton } from "./DeleteButton";
import { default as RandomIdGenerator } from "./ReactPictureRandomId";
import { toast } from "react-toastify";
import CONFIG_CONSTANT from "../../../constants/apiList";

const ImageAnnotation = props => {
  const [imagePathUrl, setImagePathUrl] = useState([""]);

  const [optionsList, setOptionsList] = useState([]);

  const [doctorIdList, setDoctorIdList] = useState([]);

  const [imageId, setImageId] = useState();

  const [validationImageId, setValidationImageId] = useState();

  const [suggestionsFound, setSuggestionsFound] = useState(false);

  const [selectedAnnotationId, setSelectedAnnotationId] = useState("000000");

  const doctorHashId = useSelector(state => state.userReducer.hash_id);

  const prescriptionData = useSelector(
    state => state.rxWriterReducer.prescriptionData
  );

  const [ann, setAnn] = useState([]);

  const [fromDoctorAiTab, setFromDoctorAiTab] = useState(false);

  const [categoryListFound, setCategoryListFound] = useState(false);

  const [categoriesList, setCategoriesList] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(
    prescriptionData.category_label || "Hair"
  );

  const [invalidationResult, setInvalidationResult] = useState("");

  const [selectedBrand, setSelectedBrand] = useState(props?.brand || "MM");

  const [appointmentId, setAppointmentId] = useState(0);

  const [selectedDoctorId, setSelectedDoctorId] = useState(0);

  const [userId, setUserId] = useState(0);

  const [pageSize, setPageSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const [validationMode, setValidationMode] = useState(false);

  const [annotationDoctorId, setAnnotationDoctorId] = useState(0);

  const [selectedImageMode, setSelectedImageMode] = useState(false);

  const [selectProfile, setSelectProfile] = useState("")

  const [selectStage, setSelectStage] = useState("Stage 1")

  const getImageUrlRequest = async (patientId, selectedImageMode) => {
    const URL = CONFIG_CONSTANT.url.GET_IMAGE_URL;
    const getImageUrlCategory = selectedImageMode
      ? { specificAnn: true }
      : {
          category: selectedCategory || "Hair",
          brand: selectedBrand || "MM",
          stageRequired: selectStage
        };
    if (patientId) {
      getImageUrlCategory.patientId = patientId;
    }
    const s3ImageUrl = await Axios.get(URL, { params: getImageUrlCategory })
      .then(response => {
        return response?.data?.data;
      })
      .catch(error => {
        const defaultUrlSlug = "bw-form-prod-doctorImg-1602408355618.jpg";
        if (
          error.response.status === 400 &&
          error.response.message ===
            "No available images that meet your criteria."
        ) {
          alert(
            "This combination of category and brand returned no results. Please try a new image"
          );
        }
        return defaultUrlSlug;
      });
    return {
      imageId: s3ImageUrl?.imageId,
      url: s3ImageUrl?.imagePreSignedUrl,
      userId: s3ImageUrl?.userId,
      appointmentId: s3ImageUrl?.appointmentId
    };
  };

  const onSelectImage = selectedId => {
    if (selectedId !== null) {
      setSelectedAnnotationId(selectedId);
    }
  };

  const onChangeImage = data => {
    setAnn(data);
  };

  const onChangeAnnotation = value => {
    let selectedIndexInFunction = 0;
    for (let i = 0; i < ann?.length; i++) {
      if (ann[i].id === selectedAnnotationId) {
        selectedIndexInFunction = i;
      }
    }
    ann[selectedIndexInFunction]["comment"] = value;
    setAnn(ann);
  };

  const onDeleteAnnotation = () => {
    const filteredAnn = ann.filter(
      element => element.id !== selectedAnnotationId
    );
    setAnn(filteredAnn);
  };

  const getImageId = async () => {
    try {
      const URL = CONFIG_CONSTANT.url.GET_IMAGE_ID;
      const imageSlugRegexResult = /(?<=(.com\/))(.*?)(?=(\?))/.exec(
        imagePathUrl
      )[0];
      const reqBodyImageIdFetch = {
        imageSlug: imageSlugRegexResult
      };
      if (reqBodyImageIdFetch !== undefined) {
        const imageId = await Axios.get(URL, { params: reqBodyImageIdFetch })
          .then(response => {
            if (response.status === 200) {
              return response?.data?.data.id;
            } else if (response.status === 404) {
              alert(
                "No image found for this combination. Please change the criteria for images."
              );
            }
          })
          .catch(error => {
            console.log(error);
            alert("Image ID not found\n");
          });
        if (imageId !== undefined || null) {
          setImageId(imageId);
          return imageId;
        }
      }
    } catch (error) {
      if (!(error.message === "Cannot read properties of null (reading '0')")) {
        console.log("GetImageID Error : ", error.message);
      }
    }
  };

  const getCategoryList = async () => {
    try {
      if (categoryListFound === false) {
        const URL = CONFIG_CONSTANT.url.GET_CATEGORY_LIST;
        const categoriesListResponse = await Axios.get(URL)
          .then(response => {
            return response?.data?.data;
          })
          .catch(error => {
            console.log(error);
            alert("Categories not found\n");
          });
        setCategoriesList(categoriesListResponse);
        const completeList = optionsList.concat(
          categoriesListResponse.map(category => {
            return category.toLowerCase();
          })
        );
        setOptionsList(completeList);
        setCategoryListFound(true);
        return categoriesList;
      }
    } catch (error) {
      console.log("GetCategoriesList Error : ", error.message);
    }
  };

  const sendRequest = async () => {
    const URL = CONFIG_CONSTANT.url.INSERT_ANNOTATION;
    const filteredAnn = ann.filter(element =>
      element.hasOwnProperty("comment")
    );
    const reqBody = {
      imageId,
      ann: filteredAnn,
      doctorHashId: doctorHashId,
      category: selectedCategory || "MM",
      brand: selectedBrand || "Hair",
      canvasWidth: pageSize.width,
      canvasHeight: pageSize.height,
      appointmentId,
      userId,
      invalidLabel: invalidationResult,
      profileLabel: selectProfile
    };
    if (filteredAnn?.length === 0 && !invalidationResult) {
      alert("Annotation not saved\n");
    } else {
      Axios.post(URL, reqBody)
        .then(response => {
          if (response?.data?.statusCode !== 200) {
            toast.error("Insertion of annotation failed");
          }
          if (fromDoctorAiTab === true) {
            if (window.confirm("Annotation(s) saved!\n")) {
            }
          } else {
            localStorage.clear();
            toast.success("Annotation saved!");
          }
        })
        .catch(error => {
          toast.error("Diagnosis not mapped in ehr, please contact admin");
        });
    }
  };

  const verifyAnnotation = async () => {
    const URL = CONFIG_CONSTANT.url.VERIFY_IMAGE_ANNOTATIONS;
    const reqBody = {
      imageId: validationImageId,
      doctorHashId: doctorHashId
    };
    Axios.post(URL, reqBody)
      .then(response => {
        if (response?.data?.statusCode !== 200) {
          toast.error("Insertion of annotation failed");
        }
        if (fromDoctorAiTab === true) {
          if (window.confirm("Annotation(s) saved!\n")) {
          }
        } else {
          localStorage.clear();
          toast.success("Annotation verified!");
        }
      })
      .catch(error => {
        toast.error("Diagnosis not mapped in ehr, please contact admin");
      });
  };
  const getSuggestionsList = async brand => {
    try {
      if (suggestionsFound === false) {
        const URL = `${CONFIG_CONSTANT.url.GET_SUGGESTIONS_LIST}/${brand}/diagnosis`;
        let suggestions = await Axios.get(URL)
          .then(response => {
            const { observationPresets } = response?.data?.data || [];
            const finalLabels = observationPresets?.map(
              observation => observation?.observationPresetText
            );
            return finalLabels;
          })
          .catch(error => {
            if (error.response.status === 404) {
              alert("Suggestions not found\n");
            }
          });
        for (let i = 0; i < suggestions?.length; i++) {
          suggestions[i] = suggestions[i].replaceAll('"', "");
        }
        setOptionsList(suggestions);
        setSuggestionsFound(true);
        return suggestions;
      }
    } catch (error) {
      console.log("GetSuggestionsList Error : ", error.message);
    }
  };

  const getDoctorIdList = async () => {
    try {
      const URL = CONFIG_CONSTANT.url.GET_ANNOTATED_DOCTORS;
      const doctorIdList = await Axios.get(URL)
        .then(response => {
          const doctorDetail = response?.data?.data || [];
          return doctorDetail;
        })
        .catch(error => {
          if (error.response.status === 404) {
            alert("Suggestions not found\n");
          }
        });
      setDoctorIdList([
        {
          doctorId: 0,
          doctorName: "None"
        },
        ...doctorIdList
      ]);
      return doctorIdList;
    } catch (error) {
      console.log("getDoctorIdList Error : ", error.message);
    }
  };

  const convertApiAnnotationToComponent = apiAnnotation => {
    const comment = apiAnnotation.annotations.replaceAll('"', "");
    const validAnnotation = {
      id: RandomIdGenerator(6),
      comment: comment,
      mark: {
        type: "RECT",
        x: apiAnnotation.top_left_x,
        y: apiAnnotation.top_left_y,
        width: apiAnnotation.width,
        height: apiAnnotation.height
      }
    };
    return validAnnotation;
  };

  const getPreviousAnnotation = async () => {
    try {
      const doctorCheckQuery = selectedDoctorId
        ? `?doctorId=${selectedDoctorId}`
        : "";
      const patientIdFieldValue =
        document.getElementById("patientIdTextField").value || "";
      const patientIdCheck = patientIdFieldValue && !doctorCheckQuery ? `?imageId=${patientIdFieldValue}` : ""
      const URL = `${CONFIG_CONSTANT.url.FECTH_IMAGE_ANNOTATIONS}${doctorCheckQuery}${patientIdCheck}`;
      const previousAnnotationsList = await Axios.get(URL, {})
        .then(response => {
          setValidationImageId(
            response?.data?.data?.imageDetails?.[0]?.image_id
          );
          return response?.data?.data;
        })
        .catch(error => {
          if (error.response.status === 400) {
            toast.error("No More Images left to validate please switch modes");
          } else if (error.response.status === 204) {
            alert("No previous annotations found for this image");
          } else {
            console.log(error.response);
          }
        });
      if (previousAnnotationsList !== undefined) {
        const validAnnotationList = previousAnnotationsList.imageDetails.map(
          convertApiAnnotationToComponent
        );
        setAnn(validAnnotationList);
        setImagePathUrl(previousAnnotationsList?.signedUrl);
        setAnnotationDoctorId(
          previousAnnotationsList?.imageDetails?.[0]?.doctor_id
        );
      }
    } catch (error) {
      console.log("GetPreviousAnnotation Error : ", error.message);
    }
  };

  const annotationDivRef = useRef(null);
  const enterRef = useRef(null);

  useEffect(() => {
    setImagePathUrl(props?.imagePath);
  }, [props?.imagePath]);

  useEffect(() => {
    if (annotationDivRef.current) {
      setPageSize({
        height: annotationDivRef.current.parentElement.offsetHeight,
        width: annotationDivRef.current.parentElement.offsetWidth
      });
    }
  }, [annotationDivRef]);

  useEffect(() => {
    const imageUrlCheck = async () => {
      if (props?.imagePath === undefined) {
        setFromDoctorAiTab(true);
        const urlResponse = await getImageUrlRequest();
        setImagePathUrl(urlResponse.url);
        setAppointmentId(urlResponse?.appointmentId);
        setUserId(urlResponse?.userId);
        setImageId(urlResponse?.imageId);
      } else {
        setImagePathUrl(props?.imagePath);
      }
    };
    imageUrlCheck();
  }, [annotationDivRef]);

  useEffect(() => {
    const suggestionsLoader = async () => {
      await getSuggestionsList(selectedBrand || "MM");
    };
    suggestionsLoader();
  }, [optionsList]);

  useEffect(() => {
    const doctorIdLoader = async () => {
      await getDoctorIdList();
    };
    doctorIdLoader();
  }, [optionsList]);

  useEffect(() => {
    const categoryLoader = async () => {
      await getCategoryList();
    };
    categoryLoader();
  }, [categoriesList]);

  const inputElementAnnotation = (
    value,
    onChangeAnnotation,
    onDeleteAnnotation
  ) => {
    return (
      <div className="rp-default-input-section" ref={enterRef}>
        <input
          id="inputAnnotation"
          list="autocomp"
          placeholder="Enter label here"
          onChange={e => onChangeAnnotation(e.target.value)}
          // onKeyUp={(e) => { if (e.key === "Enter") { onSelectImage(null); onChangeImage(ann); } }}
        />
        <datalist id="autocomp">
          {optionsList.map((element, index) => {
            return <option key={index}>{element}</option>;
          })}
        </datalist>
        <a
          className="rp-default-input-section_delete"
          onClick={() => onDeleteAnnotation()}
        >
          <Deletebutton />
        </a>
      </div>
    );
  };
  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === "Enter") {
        // Do something when Enter key is pressed
        const btn = document.querySelector(".docbtn");
        if (btn) {
          btn.click();
        }
      }
    }

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const DropDownLists = props => {
    if (props.sourceTab === true) {
      let categoryOptionsRenderList = [];
      const invalidationRenderList = [
        "",
        "Invalid Image",
        "Image too zoomed in",
        "Image too zoomed out",
        "Unable to classify",
        "Multiple subjects",
        "Bad Lighting",
        "Blurred Image"
      ];
      if (selectedBrand === "MM") {
        categoryOptionsRenderList = [
          "Skin",
          "Beard",
          "Weight",
          "Sexual",
          "Hair"
        ];
      } else {
        categoryOptionsRenderList = [
          "Skin",
          "PCOS",
          "Weight",
          "Sexual",
          "Hair",
          "UTI"
        ];
      }
      return (
        <div className="buttonSpace">
          <button
            className="docbtn"
            type="button"
            onClick={async () => {
              if (!validationMode) {
                await sendRequest();
              } else {
                await verifyAnnotation();
              }
            }}
          >
            {validationMode ? "Validate Annotation" : "Submit Annotation"}
          </button>

          <div
            style={{
              display: "inline"
            }}
          >
            <div
              style={{
                display: "inline"
              }}
            >
              <select
                className="dropdown"
                value={selectedCategory}
                onChange={async e => {
                  setSelectedCategory(e.target.value);
                }}
              >
                {categoryOptionsRenderList.map(opt => {
                  return (
                    <option key={opt.toString()} value={opt}>
                      {opt}
                    </option>
                  );
                })}
              </select>

              <select
                className="dropdown"
                value={invalidationResult}
                onChange={async e => {
                  setInvalidationResult(e.target.value);
                }}
              >
                {invalidationRenderList.map(opt => {
                  return (
                    <option key={opt.toString()} value={opt}>
                      {opt}
                    </option>
                  );
                })}
              </select>

              <select
                className="dropdown"
                value={selectedBrand}
                onChange={async e => {
                  setSelectedBrand(e.target.value);
                }}
              >
                <option value="MM">Man Matters</option>
                <option value="BW">Be Body Wise</option>
              </select>
              <select
                className="dropdown"
                value={selectStage}
                onChange={async e => {
                  setSelectStage(e.target.value);
                }}
              >
                <option value="Stage 1">Stage 1</option>
                <option value="Stage 2">Stage 2</option>
                <option value="Stage 3">Stage 3</option>
                <option value="Stage 4">Stage 4</option>
              </select>
              <select
                className="dropdown"
                value={selectProfile}
                onChange={async e => {
                  setSelectProfile(e.target.value);
                }}
              >
                <option value="Side Profile">Side Profile</option>
                <option value="Top Profile">Top Profile</option>
                <option value="Front Profile">Front Profile</option>
              </select>
              {validationMode && (
                <select
                  disabled={!validationMode}
                  className="dropdowndoctorId"
                  value={selectedDoctorId}
                  onChange={async e => {
                    setSelectedDoctorId(e.target.value);
                  }}
                >
                  {doctorIdList.map(doctorDetails => {
                    return (
                      <option
                        value={doctorDetails.doctorId}
                      >{`${doctorDetails.doctorName} (${doctorDetails.doctorId})`}</option>
                    );
                  })}
                </select>
              )}
            </div>
            <div
              style={{
                display: validationMode ? "inline" : "none"
              }}
            >
              <span>Image annotated by {annotationDoctorId}</span>
            </div>
          </div>
          <input
            type="text"
            id="patientIdTextField"
            placeholder={validationMode ? "Search by Image Id" : "Search by patient id"}
          ></input>
          <button
            className="docbtn"
            type="button"
            onClick={async () => {
              // localStorage.setItem("selectedCategory", selectedCategory);
              // localStorage.setItem("selectedBrand", selectedBrand);
              // window.location.reload(true);
              if (!validationMode) {
                const patientIdFieldValue =
                  document.getElementById("patientIdTextField").value || "";
                setSelectedCategory(selectedCategory);
                setSelectedBrand(selectedBrand);
                const urlResponse = await getImageUrlRequest(
                  patientIdFieldValue || "",
                  selectedImageMode
                );
                setImagePathUrl(urlResponse.url);
                setAppointmentId(urlResponse?.appointmentId);
                setUserId(urlResponse?.userId);
                setImageId(urlResponse?.imageId);
                setSelectedAnnotationId("000000");
                setAnn([]);
              } else {
                await getPreviousAnnotation();
              }
            }}
          >
            Next Image
          </button>
          <button
            className="docbtn"
            type="button"
            onClick={async () => {
              setValidationMode(toggle => !toggle);
            }}
          >
            {validationMode ? "Exit Validation Mode" : "Validate Images"}
          </button>

          <button
            className="docbtn"
            type="button"
            onClick={async () => {
              setSelectedImageMode(toggle => !toggle);
            }}
          >
            {selectedImageMode
              ? "Exit Selective Mode"
              : "Annotate Selective Images"}
          </button>
        </div>
      );
    } else {
      return (
        <div className="buttonSpace">
          
          <button
            className="docbtn"
            type="button"
            onClick={async () => {
              await sendRequest();
            }}
          >
            Submit Annotation
          </button>
        </div>
      );
    }
  };

  return (
    <div className="parentDoctorAi" ref={annotationDivRef}>
      <div
        className="annotation__container"
        style={{ border: "5px solid green" }}
      >
        <ReactPictureAnnotation
          loading="lazy"
          // image={"https://source.unsplash.com/random/800x600"}
          image={imagePathUrl}
          onSelect={onSelectImage}
          onChange={onChangeImage}
          scrollSpeed={0}
          width={pageSize.width}
          height={pageSize.height}
          inputElement={value =>
            inputElementAnnotation(
              value,
              onChangeAnnotation,
              onDeleteAnnotation
            )
          }
          annotationStyle={{
            ...defaultShapeStyle,
            shapeStrokeStyle: "#FF9E80",
            transformerBackground: "black",
            lineWidth: 5,
            fontBackground: "#9f82ff"
          }}
          annotationData={ann}
        />
      </div>
      <DropDownLists sourceTab={fromDoctorAiTab} />
    </div>
  );
};
export default class DoctorAi extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: props.url, brand: props.brand };
  }

  getSnapshotBeforeUpdate(props, prevstate) {
    if (prevstate.url !== props.url) {
      this.state.url = props.url;
    }
    return null;
  }

  componentDidUpdate(props, prevState, snapshot) {}

  render() {
    return (
      <>
        <ImageAnnotation imagePath={this.state.url} brand={this.state.brand} />
      </>
    );
  }
}
