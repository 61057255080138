import React from "react";
import { toast } from "react-toastify";

export const showShortCallAlert = data => {
  try {
    const { attempts, appointmentId } = data || {};
    const attemptTitleMap = {
      1: "First Attempt",
      2: "Second Attempt",
      3: "Third Attempt"
    };
    const attemptTitle = attemptTitleMap[attempts] || "Manual Attempt";
    const message = (
      <span style={{ fontSize: "18px" }}>
        The <b>{attemptTitle}</b> call made for <b>{appointmentId}</b> was a{" "}
        <b>short call</b>. You are requested to allow complete calls to go out
        to the patient
      </span>
    );

    toast.info(message, {
      autoClose: false
    });
  } catch (err) {
    console.log("Error in showShortCallAlert", err);
  }
};
