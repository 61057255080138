import axios from "axios";
import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import config from "../../../constants/apiList";
import LoadingLogin from "./LoadingLogin";
import { addToGTM } from "../../../helpers/tagManager.helper";
import { toast } from "react-toastify";
import { GTM_EVENTS } from "../../../constants/tagManager.constants";

export default function LoginCallback() {
  const { search } = useLocation();
  const history = useHistory();
  const [error, setError] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    exchangeToken();
  }, []);

  const setResponse = response => {
    localStorage.setItem("jwt", response.data.body.jwt);
    const payload = response.data.body.user;
    dispatch({ type: "USER", payload: payload });
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("jwt");
    history.push("/login");
  };

  const logoutOnError = async () => {
    localStorage.removeItem("jwt");
    await axios.get(config.AUTH.LOGOUT, { withCredentials: true });
    dispatch({ type: "CLEAR" });
    history.push("/login");
  };

  async function exchangeToken() {
    try {
      const query = new URLSearchParams(search);
      const sessionToken = query.get("sessionToken");
      const otpLogin = query.get("otplogin") === "true";
      const exchangeTokenUrl = new URL(config.AUTH.EXCHANGE_TOKEN);
      exchangeTokenUrl.searchParams.set("sessionToken", sessionToken);
      !otpLogin &&
        (await axios.get(exchangeTokenUrl.toString(), {
          withCredentials: true
        }));

      const response = await axios.post(
        config.url.LOGIN,
        { key: "" },
        { withCredentials: true }
      );
      if (response.data.statusCode === 200) {
        setResponse(response);
        const requestData = {
          doctor_id: response?.data?.body?.user?.hash_id
        };
        axios.post(config.url.GET_DOCTOR_INFO_URL, requestData).then(res => {
          if (res.status === 200) {
            addToGTM({
              event: GTM_EVENTS?.LOGIN,
              login: {
                doctor_phone: res?.data?.body?.data?.doctor_phone,
                ...(response?.data?.body?.user || {})
              }
            });
            if (window?.clevertap) {
              window.clevertap.onUserLogin.push({
                Site: {
                  Name: response?.data?.body?.user?.doctor_name,
                  Identity: res?.data?.body?.data?.doctor_id,
                  Phone: "+91" + res?.data?.body?.data?.doctor_phone
                }
              });
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      logoutOnError();
    }
  }

  React.useEffect(() => {}, []);
  return (
    <div>
      {!error && <LoadingLogin message={"Exchanging Token"} />}
      {error && <p>Something went wrong</p>}
    </div>
  );
}
