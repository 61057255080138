import React, { useState, useEffect } from "react";
import { IconButton, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import CONFIG_CONSTANT from "../../../constants/apiList";
import Loader from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import Iframe from "react-iframe";

function CheckPrescription(props) {
  const [brand, setBrand] = useState("MM");
  const [orderId, setOrderId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [pdf, setPdf] = useState("");
  const [showPdfModal, setShowPdfModal] = useState(false);
  useEffect(() => {
    if (!orderId) return;
    checkPrescription();
  }, [brand]);
  const checkPrescription = async () => {
    setShowLoader(true);
    setMessage("");
    const checkPrescriptionRequestData = {
      orderId,
      brand
    };
    await axios
      .post(
        CONFIG_CONSTANT.url.CHECK_PRESCRIPTION,
        checkPrescriptionRequestData
      )
      .then(res => {
        console.log(res);
        if (res.data.success) {
          setPdf(res.data.pdfUrl);
          setShowPdfModal(true);
        } else {
          setMessage(res.data.message);
        }
        setShowLoader(false);
      })
      .catch(err => {
        setMessage("Unable To Fetch Prescription. Try Again Later.");
        setShowLoader(false);
        console.log("ERROR IN /checkPrescription", err);
      });
  };
  return (
    <div style={{ padding: "10px" }}>
      <h4
        className="sectionHeading"
        style={{ color: "#3f51b5", marginBottom: "5px" }}
      >
        Check Prescription
      </h4>
      <div style={{ display: "flex" }}>
        <div
          onClick={() => setBrand("MM")}
          className={
            brand === "MM"
              ? `brand-filter-button brand-filter-mm active`
              : `brand-filter-button brand-filter-mm`
          }
        >
          MM
        </div>
        <div
          onClick={() => setBrand("BW")}
          className={
            brand === "BW"
              ? `brand-filter-button brand-filter-bw active`
              : `brand-filter-button brand-filter-bw`
          }
        >
          BW
        </div>
      </div>
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          alignItems: "center"
        }}
      >
        <input
          type="number"
          className="form-control"
          style={{ width: "max-content" }}
          placeholder="ORDER ID"
          value={orderId}
          name="orderId"
          onChange={evt => setOrderId(evt.target.value)}
          onKeyDown={evt => {
            if (evt.keyCode === 13) checkPrescription();
          }}
        />
        <IconButton
          color="primary"
          onClick={checkPrescription}
          style={{ margin: "0px 5px", outline: "none" }}
          disabled={!orderId}
        >
          <SearchIcon />
        </IconButton>
      </div>
      {showLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px"
          }}
        >
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "#003459" }}
          >
            {" "}
            L O A D I N G{" "}
          </span>
          <Loader
            type="ThreeDots"
            width={30}
            height={30}
            color="#003459"
            style={{ marginLeft: "5px" }}
          />
        </div>
      ) : (
        <div
          style={{
            padding: "5px 10px",
            backgroundColor: "rgba(200,0,0,0.1)",
            color: "rgb(240,0,0)",
            borderRadius: "3px",
            width: "max-content",
            fontWeight: "600"
          }}
          hidden={!message}
        >
          {message}
        </div>
      )}
      <Modal
        className="xyz"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showPdfModal}
      >
        <Modal.Body className="mdl-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Iframe
              url={pdf}
              width="700px"
              height="585px"
              id="myId"
              className="myClassname"
              display="initial"
            />
            <Button
              variant="contained"
              color="secondary"
              style={{ marginTop: "10px", width: "max-content" }}
              onClick={() => {
                setPdf("");
                setShowPdfModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CheckPrescription;
